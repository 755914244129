@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../App.scss" as *;

#header {
  position: relative;
  top: 0.55rem;
  width: 100%;
  max-width: 65rem;
  margin: auto;

  @include desktop {
    flex-flow: row nowrap;
  }
}

#header-logo {
  display: block;
  margin: auto;
  width: 16.67rem;
  filter: drop-shadow(0px 0px 0.66rem $Primary-White);

  @include desktop {
    width: 22.22rem;
  }
}

.social-icons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: -1rem auto 1rem auto;

  @include desktop {
    position: absolute;
    top: 2.75rem;
    right: 2rem;
    background-color: $Primary-White;
    padding: 0.5rem 0.5rem;
    border-radius: 2rem;
    @include drop-shadow;
  }

  &__label {
    display: none;

    @include desktop {
      display: flex;
      margin: 0 0.3rem 0.3rem 0;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  & a {
    height: 2.25rem;
  }

  &__icon {
    width: 2.25rem;
    height: 2.25rem;
    margin: 0 0.25rem;
    cursor: pointer;
    filter: drop-shadow(0px 0px 0.66rem $Primary-White);
    transition: 0.2s ease-in-out;
    fill: #282460;

    & path {
      fill: #282460;
      transition: 0.2s ease-in-out;
    }

    &:hover path {
      fill: #4940bc;
    }

    @include desktop {
      width: 2.5rem;
    }
  }
}

.social-icons__icons path {
  fill: #282460;
}