@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../App.scss" as *;
@use "../../styles/keyframes.scss" as *;

.saved-artists {
  &__artist {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
    max-height: 15rem;
    width: 100%;
    border-top: 1px solid grey;
    position: relative;
    overflow: hidden;
    background-color: rgb(255, 233, 214);
    transition: 0.3s ease-in;

    @include tablet {
      background-color: transparent;
      flex-flow: column nowrap;
      width: 28%;
      max-height: 30rem;
      margin: 0 1rem 1.67rem 1rem;
      border: 1px solid #c2c2c2;
      transition: 0.2s;
      overflow: visible;
      @include drop-shadow;

      &:hover {
        transform: scale(1.05);
      }
    }

    @include desktop {
      width: 21%;
    }

    &-name {
      margin-bottom: 1.11rem;

      @include tablet {
        height: 5.55rem;
        margin-bottom: 0;
      }
    }

    &-h3 {
      margin: 0;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.2rem;

      @include tablet {
        margin-top: 1.11rem;
      }
    }

    &-info {
      display: flex;
      flex-flow: column nowrap;
      margin: 0 auto;
      justify-content: space-evenly;
      padding: 1.5rem 0;
      overflow: hidden;

      @include tablet {
        min-height: 9.72rem;
        width: 100%;
        background-color: $Secondary-BackgroundBlue;
        padding: 0;
        overflow: scroll;
      }

      &--genre {
        font-weight: 400;
        margin-top: 0;
        font-size: 0.9rem;
        opacity: 0.75;
        line-height: 1rem;
      }
    }

    &--delete {
      max-height: 0rem;
      overflow: hidden;
      border: 0;

      @include tablet {
        height: auto;
        opacity: 1;
        overflow: visible;
        -webkit-animation: flip-out-ver-left 0.5s
          cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        animation: flip-out-ver-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
          both;
      }
    }

    &-name {
      padding: 0 0.5rem;
    }
  }

  &__thumb {
    aspect-ratio: 1/1;
    max-width: 50%;
    min-width: 50%;
    background-color: grey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include tablet {
      aspect-ratio: 1/1;
      min-width: 100%;
    }
  }

  &__link {
    background-color: $Primary-Submit;
    color: $Primary-White;
    margin: 0 auto;
    padding: 0.27rem 1rem;
    font-size: 0.78rem;
    transition: 0.3s;
    @include button;

    &:hover {
      background-color: $Secondary-SubmitHover;
    }

    @include tablet {
      margin: auto 1.11rem;
      font-size: 1rem;
      padding: 0.55rem 1.11rem;
    }
  }

  &__remove-like--tablet {
    @include tablet {
      position: absolute;
      right: -0.55rem;
      top: -0.55rem;
      width: 1.67rem;
      height: 1.67rem;
      border-radius: 0.83rem;
      background-color: $Secondary-Red;
      background-image: url("../../assets/icons/xmark-solid.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      color: white;
      transition: 0.2s;

      &:hover {
        background-color: red;
        cursor: pointer;
      }
    }
  }

  &__remove-like--mobile {
    position: absolute;
    right: 0.27rem;
    top: 0.27rem;
    color: $Primary-White;
    background-color: red;
    padding: 0 0.55rem;
    border-radius: 0.83rem;
    font-weight: 400;
    @include drop-shadow;

    @include tablet {
      display: none;
      filter: none;
    }
  }
}