@use "../../styles/variables" as *;
@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;
@use "../../App.scss" as *;

.nav-bar {
  display: contents;
}

.home__loading {
  margin: auto;
  margin-top: 2rem;
}
