@use '../../styles/variables' as *;
@use '../../styles/typography' as *;
@use '../../styles/mixins' as *;
@use '../../App.scss' as *;

.artist {
  // padding: 0 1.11rem;

  @include tablet {
    max-width: 768px;
    width: 100%;
    margin: auto;
  }
}

.artist__player {
  margin-top: 1.2rem;
  padding: 0 1.11rem;

  @include desktop {
    display: flex;
    margin: 1.2rem auto 0 auto;
    flex-flow: row nowrap;
    width: 44.44rem;
  }

}

.artist__comments {
  padding: 0 1.11rem;

  @include tablet {
    width: 25rem;
  }

  @include desktop {
    width: 100%;
    max-width: 30rem;
    margin: 0 auto 4rem auto;
    padding: 0;
  }
}
