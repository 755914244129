@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../App.scss" as *;

.avatar-modal {
  height: 100vh;
  width: 100vw;
  margin: auto;
  background-color: #00000080;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;

  &__info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    background-color: $Primary-White;
    position: absolute;
    width: 75%;
    top: 25%;
    left: 12.5%;
    padding: 20px;
    border-radius: 20px;

    @include tablet {
      width: 16rem;
      left: 25%;
    }

    @include desktop {
      left: 0;
      margin: 0 37.5%;
      width: 25%;
    }
  }

  &__top-row {
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: space-between;

    &--close {
      height: 25px;
      min-width: 25px;
      background-image: url("../../assets/icons/xmark-solid.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50%;
      background-color: $Secondary-Red;
      border-radius: 12.5px;
      border: none;
      position: relative;
      margin-left: auto;
      transition: 0.2s ease-in-out;
      cursor: pointer;

      @include tablet {
        &:hover {
          background-color: $Secondary-Red;
        }
      }
    }
  }

  &__heading {
    margin: 0;
    line-height: 2rem;
  }

  &__form {
    margin-top: 30px;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    text-align: center;
    line-height: normal;

    @include tablet {
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  &__upload-file {
    background-color: rgb(208, 208, 208);
    border: 0px;
    padding: 10px 20px;
    color: white;
    border-radius: 20px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    pointer-events: none;
    @include drop-shadow;

    @include tablet {
      &:hover {
        background-color: $Secondary-SubmitHover;
      }
    }

    &--input {
      display: none;
      margin-right: 0.5rem;
    }

    &--submit {
      background-color: green;
      border: 0px;
      cursor: pointer;

      @include tablet {
        &:hover {
          background-color: rgb(0, 186, 0);
        }
      }
    }

    &--max-filesize-exceeded {
      background-color: pink;
      color: $Secondary-Red;
      border: 0px;
      pointer-events: none;
    }
  }
}
