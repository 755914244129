@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;
@use '../../styles/typography' as *;
@use '../../App.scss' as *;

.comment {
  border-top: 1px solid $Primary-White;

  &__container {
    padding: 1.1rem;
    display: flex;
    flex-flow: row nowrap;
  }

  &__avatar {
    width: 2.22rem;
    height: 2.22rem;
    border-radius: 1.11rem;
    background-image: url('../../assets/icons/profile-avatar.jpeg');
    background-size: contain;
    flex-shrink: 0;

    @include tablet {
      width: 3rem;
      height: 3rem;
      border-radius: 1.5rem
    }
  }

  &__info {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  &__name-date--desktop {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
  }

  &__name {
    margin: 0 0 0.3rem 0.55rem;
    font-weight: 600;

    &--mobile {
      @include tablet {
        display: none;
      }
    }
  }

  &__text {
    width: 100%;
    flex-shrink: 1;
    margin: 0 0 0 0.55rem;
    line-height: 1.22rem;
  }
}