@use './styles/variables' as *;
@use './styles/typography' as *;
@use './styles/mixins' as *;

* {
  box-sizing: border-box;
  font-family: 'Josefin Sans';
}

html {
  margin: 0;
  width: 100vw;
  font-size: 18px;
  line-height: 26px;
}

body {
  background-color: $Primary-Background;
  padding-bottom: 60px;
  min-height: 100vh;
}

main {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  max-width: 65rem;
  margin: auto;
}

a {
  color: $Secondary-SubmitHover;
}