@-webkit-keyframes flip-out-ver-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-70deg);
            transform: rotateY(-70deg);
    opacity: 0;
  }
}
@keyframes flip-out-ver-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-70deg);
            transform: rotateY(-70deg);
    opacity: 0;
  }
}



@-webkit-keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
            transform: rotateX(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
            transform: rotateX(70deg);
    opacity: 0;
  }
}




@-webkit-keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
@keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}