@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../App.scss" as *;
@use "../../styles/keyframes.scss" as *;

.home {
  &__heading {
    text-align: center;
    margin-top: 2.5rem;
    transition: none;
  }

  &__desktop-toggle {
    @include desktop {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      margin: 0 7.22rem 2.22rem;
    }
  }
}

.inputs {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 1.11rem auto;
  width: 90%;
  text-align: center;

  @include tablet {
    padding: 0 1.11rem;
  }

  @include desktop {
    width: 50%;
    position: sticky;
    top: 2.22rem;
    border-right: 1px solid white;
  }

  &__recommendation {
    &-container {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-bottom: 1rem;
      width: 100%;
    }

    &-toggle {
      width: 3.25rem;
      height: 2rem;
      border-radius: 1rem;
      background-color: $Secondary-Red;
      margin: 0 1rem;
      transition: 0.2s ease-in;
      position: relative;
      @include drop-shadow;

      &--container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        background-color: $Primary-White;
        @include input;
        width: 100%;
        height: 3rem;
        max-width: 19.67rem;

        @include desktop {
          margin-bottom: 1.17rem;
          height: 4.1rem;
        }
      }

      &--label {
        font-size: 0.9rem;
        font-style: italic;

        @include tablet {
          font-size: 1rem;
        }

        @include desktop {
          font-size: 1.33rem;
        }
      }

      &--heading {
        margin: 0;
      }
    }

    &-selector {
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
      border: 2px solid $Secondary-Red;
      background-color: $Primary-White;
      position: absolute;
      right: 1.25rem;
      transition: 0.1s ease-in;

      &--song {
        right: 0%;
      }
    }
  }

  &__genre {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 19.67rem;
  }

  &__form {
    width: 90%;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.11rem;
    text-align: center;

    @include tablet {
      max-width: 22rem;
    }

    @include desktop {
      margin-top: 0;
      font-size: 1.33rem;
    }
  }
}

.app-inputs {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 30%;
}

.form {
  &__first-parameter {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    height: 7.5rem;
    position: relative;

    @include desktop {
      height: 7rem;
    }
  }

  &__input-label {
    text-align: center;

    @include desktop {
      font-size: 1.2rem;
    }

    &--popularity {
      @include desktop {
        max-width: 342px;
        margin-top: 1rem;
      }
    }
  }

  &__input--popularity-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 0.55rem 0 1rem 0;
    padding: 0 1rem;
    width: 100%;
    max-width: 19.67rem;
    background: $Primary-White;
    border-radius: 1rem;
    @include drop-shadow;

    @include tablet {
      justify-content: center;
      width: 100%;
      max-width: 19.67rem;
      margin: 0.55rem 0 1rem 0;
    }

    @include desktop {
      margin: 0.55rem 0 2rem 0;
    }
  }

  &__input--popularity-label {
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 600;

    @include tablet {
      font-size: 1rem;
      flex-grow: 0;
    }

    @include desktop {
      font-size: 1.33rem;
    }
  }

  &__submit,
  &__submit--loading {
    padding: 0.28rem 1.67rem;
    border-radius: 1.1rem;
    border: 0;
    background: $Primary-Submit;
    background-position: 50%;
    color: $Primary-White;
    font-weight: 600;
    @include button;
    min-width: 9.55rem;
    min-height: 2.154rem;

    &:active {
      transform: none;
      min-width: 9.55rem;
    }

    @include desktop {
      font-size: 1.33rem;
      padding: 0.55rem 1.5rem;
      min-width: 12.78rem;
      min-height: 2.432rem;

      &:active {
        transform: none;
      }
    }

    &:hover {
      background-color: $Secondary-SubmitHover;
    }

    &--loading {
      background-image: url(../../assets/images/fadeStaggerCircles.svg);
      background-repeat: no-repeat;
      background-size: 35%;
      background-position: 50%;
      min-height: 2.155rem;

      @include desktop {
        min-width: 12.78rem;
        min-height: 2.432rem;
      }
    }
  }

  &__custom-input {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    text-align: center;

    &--result-list {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      max-height: 18rem;
      overflow: scroll;
      border: 1px solid gray;
      position: absolute;
      top: 4.5rem;
      z-index: 999;
    }
  }
}

.custom-input {
  @include desktop {
    font-size: 1.2rem;
  }

  &__search-bar {
    @include input;
    height: 2.5rem;
    width: 100%;
    max-width: 19.67rem;
    padding: 0.5rem;
    text-align: center;
    @include drop-shadow;

    &:focus::placeholder {
      color: #ffffff00;
    }
  }

  &__container {
    width: 100%;
    max-width: 22rem;
  }

  &__selected-artist {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 19.67rem;
    margin-top: 0.27rem;
    border: 1px solid lightgray;
    border-radius: 1rem;

    &--track {
      width: 100%;
      color: $Primary-White;
      background-color: $Primary-Submit;
      padding: 0.5rem;
      border-radius: 1rem 1rem 0 0;
      white-space: nowrap;
      overflow: hidden;
    }

    &--artist {
      width: 100%;
      background-color: $Secondary-BackgroundBlue;
      padding: 0.5rem;
      border-radius: 0 0 1rem 1rem;
      white-space: nowrap;
      overflow: hidden;
    }

    &--delete {
      position: absolute;
      right: -0.5rem;
      top: -0.5rem;
      padding: 0;
      background-image: url(../../assets/icons/xmark-solid.svg);
      background-size: 50%;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: $Secondary-Red;
      height: 1.5rem;
      width: 1.5rem;
      border: none;
      border-radius: 1rem;

      @include desktop {
        right: -0.8rem;
        top: -0.8rem;
        height: 2rem;
        width: 2rem;
      }

      &-container {
        position: absolute;
        right: 0%;
        display: flex;
        align-items: center;
        height: 100%;
        background-color: pink;
      }
    }
  }
}

#inputs__genre-selector,
#inputs__pop-selector {
  margin: 0.55rem 1.11rem;
}

#inputs__genre-selector {
  @include input;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 0.28rem 0;
  border: 0;
  border-radius: 1.11rem;
  text-align: center;
  text-align-last: center;
  height: 2.5rem;
  background-color: $Primary-White;
  color: $Primary-Submit;
  background-image: url(../../assets/icons/dropdown.svg);
  background-size: 8%;
  background-repeat: no-repeat;
  background-position: 95%;

  @include tablet {
    width: 100%;
    font-size: 1.3rem;
    max-width: 19.67rem;
  }

  & option {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    margin: auto;
    color: $Primary-Submit;
  }

  &:focus {
    outline: 2px solid $Secondary-SubmitHover;
  }
}

#inputs__pop-selector {
  -webkit-appearance: none;
  appearance: none;
  flex-grow: 1;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -7px;
    background-color: $Primary-White;
    border: 2px solid black;
    border-radius: 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -5px;
    background-color: $Primary-White;
    border: 2px solid black;
    border-radius: 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  &::-moz-range-track {
    border-radius: 10px;
    background: linear-gradient(0.25turn, rgb(255, 238, 238), $Secondary-Red);
    height: 0.75rem;
    border: 1px solid grey;
  }

  &::-webkit-slider-runnable-track {
    border-radius: 10px;
    background: linear-gradient(0.25turn, rgb(255, 238, 238), $Secondary-Red);
    height: 0.75rem;
    border: 1px solid grey;
  }
}

select option {
  padding-right: 70%;
  color: $Primary-Submit;
}
